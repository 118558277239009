jQuery(function($) {
  $('.slider').each(function() {
    var slider = $(this)
    var config = {
      pageDots: slider.hasClass('has-dots'),
      prevNextButtons: slider.hasClass('has-arrows'),
      wrapAround: true,
      imagesLoaded: true,
      contain: true,
      autoPlay: 3000
    }

    slider.flickity(config)
  })
})


jQuery(function($) {
  $('.latest-projects-slider').each(function() {
    var slider = $(this)
    var config = {
      prevNextButtons: slider.hasClass('has-arrows'),
      wrapAround: true,
      imagesLoaded: true,
      contain: true,
      cellAlign: 'left'
    }

    slider.flickity(config)
  })
})


jQuery(document).ready(function(){
  jQuery('.project-slider').each(function() {
    jQuery(this).flickity({
        autoPlay: 5000,
        prevNextButtons: false,
        imagesLoaded: true,
        pageDots: false,
        setGallerySize: true,
        wrapAround: jQuery(this).children().length > 3 ? true : false,
        draggable: true,
        cellAlign: 'left',
        contain: true
    });
  })

    jQuery('.carousel-main').flickity({
      autoPlay: 8000,
      prevNextButtons: true,
      imagesLoaded: true,
      pageDots: false,
      setGallerySize: true,
      wrapAround: true,
      draggable: false
    });

    jQuery('.carousel-nav').flickity({
      asNavFor: '.carousel-main',
      contain: true,
      prevNextButtons: false,
      pageDots: false
    });

    jQuery('.project-slider').addClass('active');
});
